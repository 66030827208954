import axios from 'axios';
import { load } from 'cheerio';

const getHTMLText = () => {
  return axios.get('https://commits.top/honduras_private.html').then((response) => response.data);
};

const getParsedValues = async () => {
  try {
    const text = await getHTMLText();
    const parsedPage = load(text);
    const tableData = parsedPage('table.users-list tbody');
    let index = 0;
    for(let i = 0; i < tableData[0].children.length; i += 1) {
      const row = tableData[0].children[i]
      if (row.name === 'tr') {
        index += 1;
        const columns = row.children.filter((column) => column.name === 'td');
        const { children } = columns[1] || [];
        const result = children[0].firstChild?.data === 'ajomuch92';
        if (result) {
          return {
            index,
            contributions: parseInt(columns[2]?.firstChild?.data) || 0
          }
        }

      }
    }
    return null;
  } catch {
    console.log('error');
    return null;
  }
};

export default getParsedValues;