<template>
  <main class="w-full h-custom pt-10 pb-24 flex justify-center">
    <div class="w-1/2 xsm:w-5/6 sm:w-4/3">
      <h1 class="text-3xl font-bold text-center">My Certifications</h1>
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        Certifications and Others
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card tag="a" href="https://www.efset.org/cert/6fV91k" target="_blank">
          <te-icon name="translate" class="text-3xl"/>
          <span class="text-center">EF Set English Certification</span>
        </card>
        <card tag="a" href="https://api.badgr.io/public/assertions/M4DTVvn5Qh6tyQMnDpf71g?identity__email=ajomuch92%40gmail.com" target="_blank">
          <img class="h-16" src="../assets/vue_certified_dev.png" />
          <span class="text-center">Vue Certified Developer</span>
        </card>
        <card tag="a" href="https://developers.google.com/profile/u/103046814370371731104" target="_blank">
          <te-icon name="google" class="text-3xl"/>
          <span class="text-center">Google Dev Profile</span>
        </card>
      </section>
      <template v-if="result">
        <hr class="my-5" />
        <p class="my-5 text-xl">
          <te-icon name="braces" />
          My position in my Honduras according with <a class="text-own-blue" href="https://commits.top/honduras_private.html" target="_blank">Most Active User Ranking</a>
        </p>
        <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
          <card>
            <span class="text-3xl">{{ result.index }}</span>
            <span class="text-center">Position</span>
          </card>
          <card>
            <span class="text-3xl">{{ result.contributions }}</span>
            <span class="text-center">Contributions last year on GitHub</span>
          </card>
        </section>
      </template>
    </div>
  </main>
</template>

<script>
import Card from '../components/Card.vue';
import getParsedValues from '../data/rank';

export default {
  name: 'Certifications',
  components: { Card },
  data: () => ({
    result: undefined,
  }),
  mounted() {
    this.getPageRank();
  },
  methods: {
    async getPageRank() {
      const loading = this.$showLoading({
        size: 'large',
        type: 'growing',
        color: 'light'
      });
      const result = await getParsedValues();
      if (result) {
        this.result = result;
      }
      loading.close();
    }
  }
}
</script>

<style>

</style>